
import { Component, Vue } from 'vue-property-decorator';

import FormInput from '@/components/common/FormInput.vue';
import ErrorDisplay from '@/components/common/ErrorDisplay.vue';

import User from '@/store/modules/User';
import ApiError from '@/services/api/models/ApiError';

import { Validation, validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';

@Component({
  components: {
    FormInput,
    ErrorDisplay
  },
  validations: {
    form: {
      email: {
        required,
        email
      }
    }
  },
  mixins: [validationMixin]
})
export default class ForgotPassword extends Vue {
  public error: ApiError | null = null;
  public form = {
    email: ''
  };
  public attemptSubmit: boolean | null = null;
  public submitted = false;
  public unresolvableError = false;

  public async onSubmit(): Promise<void> {
    this.$v.form.$touch();
    if (this.$v.form.$anyError) {
      this.attemptSubmit = false;
      return;
    }
    this.error = null;
    try {
      await User.forgotPassword({
        email: this.form.email ?? ''
      });

      this.submitted = true;
    } catch (error) {
      if (
        (error as ApiError).status == 401 ||
        (error as ApiError).status == 404
      ) {
        this.unresolvableError = true;
        this.submitted = true;
      } else {
        this.error = error as ApiError;
      }
    }
  }

  public validateState(name: string): boolean | null {
    const { $dirty, $invalid } = this.$v.form[name] as Validation;
    return $dirty ? !$invalid : null;
  }
}
